import {Label} from '@radix-ui/react-label'
import {useField} from '@rvf/remix'
import {cx} from 'class-variance-authority'

export interface TextareaProps
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  name: string
  label: string
}

export const Textarea: React.FC<TextareaProps> = ({name, label, className, ...rest}) => {
  const field = useField<string>(name)
  const error = field.error()

  return (
    <div>
      <Label htmlFor={name} className="mb-2 block text-sm text-radix-mauve11">
        {label}
      </Label>
      <textarea
        id={name}
        name={name}
        {...field.getInputProps({
          id: name,
          value: field.value(),
          onChange: (e) => {
            field.setValue(e.target.value)
          },
        })}
        className={cx(
          'block w-full rounded border border-radix-mauve7 bg-radix-mauve1 text-sm hover:border-radix-mauve8 focus:outline-none focus:ring-0 active:border-radix-mauve8',
          className,
        )}
        {...rest}
      />
      {error && <div className="mt-1 text-xs text-radix-red11">{error}</div>}
    </div>
  )
}
